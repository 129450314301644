const adminAjax = window.adminAjax;

export default {
	init() {
		// Categories actions
		$(document).on('click', '.category__item__name, .category__subitem__name', function () {
			var element = $(this);

			if (element.hasClass('active')) {

				element.find('+ .categories__sublist, + .categories__subsublist').stop().slideUp(function () {
					element.removeClass('active');
				});
			} else {
				element.addClass('active');

				element.find('+ .categories__sublist, + .categories__subsublist').stop().slideDown();
			}
		});

		$('.js-filter-tutos').on('change', function(){
			const filter = $(this).val();
			const type = $(this).attr('name');
			const search = $('input[name=s]').val();
			$.ajax({
				type: 'POST',
				url: adminAjax.ajaxurl,
				data: {
					security: adminAjax.security,
					filter: filter,
					type: type,
					search: search,
					action: 'filter_tutos',
				},
				beforeSend: function(){
					$('#results').addClass('loading');
				},
				success: function (data) {
					$('#results').removeClass('loading');
					$('#results').html(data);
					$('.js-pagination').hide();
				},
			});
		})
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
