import Plyr from 'plyr';

export default new class Video {
    initVideo() {
        if($('.js-video').length > 0){
			const players = Array.from(document.querySelectorAll('.js-video')).map((p) => new Plyr(p,
				{
					controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen'],
				}));

			players.forEach(function(player){
				player.toggleControls(false)
				player.on('play', () => {
					player.toggleControls(true);
				});
			})

            window.addEventListener('ready', () => {
                //const player = event.detail.plyr;
            });
        }
    }
}
