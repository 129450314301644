const adminAjax = window.adminAjax;

export default {
	init() {
		// JavaScript to be fired on the home page
		$('.js-filter-integrations').on('change', function(){
			const term = $(this).val();
			$.ajax({
				type: 'POST',
				url: adminAjax.ajaxurl,
				data: {
					security: adminAjax.security,
					term: term,
					action: 'filter_integrations',
				},
				beforeSend: function(){
					$('#results').addClass('loading');
				},
				success: function (data) {
					$('#results').removeClass('loading');
					$('#results').html(data);
				},
			});
		})
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
