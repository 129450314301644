import Swiper from 'swiper';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';

import { tns } from "tiny-slider/src/tiny-slider.js";

export default new (class Slideshow {
	initSlideshow() {
		if ($(".slideshow--testimonials").length > 0) {
			$(".slideshow--testimonials .slideshow__slides").each(function () {
				var prevButton = $(this)
					.parents(".slideshow--testimonials")
					.find(".slideshow__controls__prev")[0];
				var nextButton = $(this)
					.parents(".slideshow--testimonials")
					.find(".slideshow__controls__next")[0];

				tns({
					container: this,
					slideBy: 1,
					speed: 800,
					loop: false,
					prevButton: prevButton,
					nextButton: nextButton,
					nav: false,
					responsive: {
						0: {
							gutter: 20,
							items: 1,
						},
						768: {
							gutter: 40,
							items: 2,
						},
						1280: {
							gutter: 40,
							items: 3,
						},
					},
				});
			});
		}

		if ($(".slideshow--activities").length > 0) {
			$(".slideshow--activities .slideshow__slides").each(function () {
				var prevButton = $(this)
					.parents(".slideshow--activities")
					.find(".slideshow__controls__prev")[0];
				var nextButton = $(this)
					.parents(".slideshow--activities")
					.find(".slideshow__controls__next")[0];

				tns({
					container: this,
					slideBy: 1,
					speed: 800,
					loop: false,
					prevButton: prevButton,
					nextButton: nextButton,
					nav: false,
					responsive: {
						0: {
							gutter: 20,
							items: 1,
						},
						576: {
							gutter: 40,
							items: 2,
						},
						900: {
							gutter: 40,
							items: 3,
						},
						1280: {
							gutter: 40,
							items: 4,
						},
					},
				});
			});
		}

		if ($(".slideshow--integrations").length > 0) {
			$(".slideshow--integrations .slideshow__slides").each(function () {
				var prevButton = $(this)
					.parents(".slideshow--integrations")
					.find(".slideshow__controls__prev")[0];
				var nextButton = $(this)
					.parents(".slideshow--integrations")
					.find(".slideshow__controls__next")[0];

				tns({
					container: this,
					slideBy: 2,
					gutter: 20,
					speed: 800,
					loop: false,
					prevButton: prevButton,
					nextButton: nextButton,
					nav: false,
					responsive: {
						576: {
							gutter: 40,
							items: 2,
						},
						900: {
							gutter: 40,
							items: 3,
						},
						1280: {
							items: 4,
						},
						1440: {
							items: 6,
						},
					},
				});
			});
		}

		if ($(".slideshow--logos").length > 0) {
			$(".slideshow--logos").each(function () {
				if ($(window).width() > 1280 && $(this).find('.logo').length > 8
					|| $(window).width() <= 1280 && $(this).find('.logo').length > 7
					|| $(window).width() <= 800 && $(this).find('.logo').length > 5
					|| $(window).width() <= 576 && $(this).find('.logo').length > 3) {
					tns({
						container: this,
						slideBy: 1,
						speed: 5000,
						loop: true,
						controls: false,
						nav: false,
						autoplay: true,
						mouseDrag: true,
						autoplayTimeout: 5000,
						responsive: {
							0: {
								gutter: 20,
								items: 3,
							},
							576: {
								gutter: 40,
								items: 5,
							},
							900: {
								gutter: 40,
								items: 7,
							},
							1280: {
								gutter: 40,
								items: 8,
							},
						},
					});
				}
			});
		}

		const swipersPosts = document.querySelectorAll('.js-swiper-cards');
		if (swipersPosts.length > 0) {
			swipersPosts.forEach(function (el) {
				new Swiper(el, {
					modules: [Navigation],
					watchSlidesProgress: true,
					slideVisibleClass: 'active',
					slidesPerView: 1,
					// slideToClickedSlide: true,
					spaceBetween: 40,
					speed: 600,
					autoplay: false,
					navigation: {
						nextEl: ".swiper-button-next",
						prevEl: ".swiper-button-prev",
					},
					breakpoints: {
						640: {
							slidesPerView: 2,
							spaceBetween: 16,
						},
						1024: {
							slidesPerView: 3,
						},
					},
				});
			});
		}

		const swiperSlideshows = document.querySelectorAll('.js-swiper-slideshow');
		if (swiperSlideshows.length > 0) {
			swiperSlideshows.forEach(function (el) {
				const swiper = new Swiper(el, {
					modules: [Pagination, Navigation],
					slidesPerView: "auto",
					slideVisibleClass: 'active',
					centeredSlides: true,
					spaceBetween: 30,
					lazy: true,
					slideToClickedSlide: true,
					init: false,
					pagination: {
						el: ".swiper-pagination",
						clickable: true,
					},
					navigation: {
						nextEl: ".swiper-button-next",
						prevEl: ".swiper-button-prev",
					},
				});
				swiper.on('afterInit', function () {
					swiper.slideTo(0, 0);
				})
				swiper.init();
			});
		}

		const swiperVerticals = document.querySelectorAll('.js-swiper-vertical');
		if (swiperVerticals.length > 0) {
			swiperVerticals.forEach(function (el, index) {
				new Swiper(el, {
					modules: [Autoplay],
					slidesPerView: "auto",
					autoHeight: true,
					direction: "vertical",
					autoplay: {
						delay: 0,
						reverseDirection: index % 2 == 0,
					},
					speed: 3000,
					loop: true,
					loopedSlides: 2,
					spaceBetween: 20,
				});
			});
		}
	}
})();
