
export default new class Helper {

	// Add "has-scrolled" class if not in the top of the page
	initScrollHeader(identifier, threshold) {
		var element = $(identifier);
		if (element.length > 0) {
			element.each(function () {
				$(document).scroll(function () {
					if ($(this).scrollTop() >= threshold + 160) {
						$('body').addClass('has-scrolled-first');
					} else {
						$('body').removeClass('has-scrolled-first');
					}

					if ($(this).scrollTop() >= threshold + 200) {
						$('body').addClass('has-scrolled-second');
					} else {
						$('body').removeClass('has-scrolled-second');
					}
				});
			});
		}
	}

	// Remove hover effect on tablet/smartphone
	initTouch() {
		var ua = window.navigator.userAgent;
		var edge = ua.indexOf('Edge');

		function hasTouch() {
			return 'ontouchstart' in document.documentElement
				|| navigator.maxTouchPoints > 0
				|| navigator.msMaxTouchPoints > 0;
		}

		if (hasTouch()) { // remove all the :hover stylesheets
			if (edge == -1) {
				try { // prevent exception on browsers not supporting DOM styleSheets properly
					for (var si in document.styleSheets) {
						var styleSheet = document.styleSheets[si];
						if (!styleSheet.rules) continue;

						for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
							if (!styleSheet.rules[ri].selectorText) continue;

							if (styleSheet.rules[ri].selectorText.match(':hover')) {
								styleSheet.deleteRule(ri);
							}
						}
					}
				} catch (ex) { console.log(''); }
			}
		}
	}

	// Smooth scroll
	initSmoothScroll() {

		// If come of another page
		if (window.location.hash) {
			// Scroll top
			scroll(0, 0);
			setTimeout(function () { scroll(0, 0); }, 1); // Takes care of some browsers issue

			// Get target element
			var target = window.location.hash;
			var $target = $(target);

			// Calcul scrollTop
			var $scrollTop = $target.offset().top - 120;

			// Scroll to target element
			$('html, body').stop().animate({
				'scrollTop': $scrollTop,
			}, 900, 'swing');
		}

		// Smooth scrolling when clicking on a anchor link
		$('a[href*="#"]').on('click', function (e) {
			if ($(this).attr('href').indexOf('#popup') == -1 && $(this).attr('href').indexOf('#channel') == -1 && $(this).attr('href') !== '#') {
				e.preventDefault();

				// Get target element
				var target = this.hash;
				var $target = $(target);

				// Calcul scrollTop
				var $scrollTop = $target.offset().top - 120;

				// Scroll to target element
				$('html, body').stop().animate({
					'scrollTop': $scrollTop,
				}, 900, 'swing');
			}
		});
	}

	// Menu actions
	initMenu() {
		// Open/close aside menu
		$(document).on('click', '.hamburger', function () {
			var current = $(this);
			current.toggleClass('is-active');
			$('body').toggleClass('nav-is-opened');
		});

		// Open submenu
		const menuItems = $('.js-nav-arrow').parents('.menu-item-has-children');
		menuItems.each(function () {
			$(this).on('click', function (e) {
				if (!$(e.target).hasClass('nav__sublink')) {
					e.preventDefault();
					var current = $(this);
					$('.menu-item-has-children').not(current).removeClass('is-active');
					current.toggleClass('is-active');
				}
			})
		});


		$(window).on('resize', function () {
			if ($(window).width() >= 1280) {
				$('.hamburger').removeClass('is-active');
				$('body').removeClass('nav-is-opened');
			}
		})
	}

	// Translate label form
	initDisplayLabel() {
		$('input').on('focus', function () {
			$(this).parents('.form__element').find('label').addClass('active');
		});
		$('input').on('focusout', function () {
			if ($(this).val() == '') {
				$(this).parents('.form__element').find('label').removeClass('active');
			}
		});
		$('select').on('focus', function () {
			$(this).parents('.form__element').find('label').addClass('active');
		});
		$('select').on('focusout', function () {
			if ($(this).val() == '') {
				$(this).parents('.form__element').find('label').removeClass('active');
			}
		});
		$('textarea').on('focus', function () {
			$(this).parents('.form__element').find('label').addClass('active');
		});
		$('textarea').on('focusout', function () {
			if ($(this).val() == '') {
				$(this).parents('.form__element').find('label').removeClass('active');
			}
		});
	}

	replaceNonBreaking(text) {
		return text.replace(/ (!|:|\?|…|;|»)/g, "&nbsp;$1").replace('« ', '«&nbsp;');
	}

	// Dropdowns actions
	initDropdowns() {
		$(document).on('click', '.js-dropdown-button', function () {
			var element = $(this);

			if (element.hasClass('open')) {

				element.parents('.js-dropdown').find('.js-dropdown-content').stop().slideUp(function () {
					element.removeClass('open');

					element.attr('aria-expanded', false);
					element.parents('.js-dropdown').find('.js-dropdown-content').attr('aria-hidden', true);
				});
			} else {
				element.addClass('open');

				element.attr('aria-expanded', true);

				element.parents('.js-dropdown').find('.js-dropdown-content').stop().slideDown();
				element.parents('.js-dropdown').find('.js-dropdown-content').attr('aria-hidden', false);
			}
		});

	}
}
