// Import external dependencies
import 'jquery';

// Import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import templateIntegrations from './routes/template-integrations';
import tuto from './routes/tuto';

/** Populate Router instance with DOM routes */
const routes = new Router({
	// All pages
	common,
	// Home page
	home,
	// Template integrations
	templateIntegrations,
	// Templates liés aux tutos
	tuto,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
