import Rellax from "rellax";
import Cookies from 'js-cookie';
import AOS from "aos";
import { preloadImages } from '../util/preloadImages';
import lozad from 'lozad';

import Helper from "../util/Helper";
import Slideshow from "../util/Slideshow";
import Video from "../util/Video";

export default {
	init() {
		// JavaScript to be fired on all pages

		// Remove class 'preload'
		$("html").removeClass("preload");

		// Initializations
		Helper.initScrollHeader("main", 0);
		Helper.initTouch();
		Helper.initMenu();
		Helper.initDisplayLabel();
		Helper.initDropdowns();
		Video.initVideo();

		// Chatbox
		if ($('.js-chatbox').length > 0) {
			if (Cookies.get('restomax-chatbox') != 'closed') {
				$('.js-chatbox').delay(2000).fadeIn();
				$('.js-chatbox').find('.js-close').on('click', function () {
					$(this).parent('.js-chatbox').fadeOut();
					Cookies.set('restomax-chatbox', 'closed');
				});
			}
		}

		$('.editor').each(function () {
			$(this).html(Helper.replaceNonBreaking($(this).html()));
		})
		new Rellax(".rellax", {
			center: true,
		});

		$('.js-copy-link').on('click', function () {
			console.log($(this));
			// Get the text field
			var copyText = window.location;
			// Copy the text inside the text field
			navigator.clipboard.writeText(copyText);
			$(this).text('✔');
			$(this).addClass('checked')
		})

	},
	finalize() {
		// JavaScript to be fired on all pages, after the init JS
		AOS.init({
			duration: 700,
			offset: 100,
		});
		preloadImages('img').then(function () {
			console.log('images loaded');
			Slideshow.initSlideshow();
		})
		Helper.initSmoothScroll();
		const observer = lozad(); // lazy loads elements with default selector as '.lozad'
		observer.observe();
		document
			.querySelectorAll("img")
			.forEach((img) =>
				img.addEventListener("load", () => AOS.refresh())
			);
	},
};
